.App {
    font-family: sans-serif;
    text-align: center;
  }

.container {
  background-position: top;
  /* background-image: url('https://close-encounter.s3.ap-northeast-1.amazonaws.com/background.png'); */
  background-size: cover;
  background-size: 750px auto;
  background-repeat: no-repeat;
  background-color: #FAFAFA;
}

.ranking_container {
  width: 85%;
  background-color: #FAFAFA;
  padding: 20px;
  margin: auto;
}

.ranking_container hr {
  margin-top: 90px;
}

.title {
  width: 100%;
  height: 200px;
  background-image: url('https://technical-books.s3.ap-northeast-1.amazonaws.com/pc_cg.jpg');
  background-size: cover;
  background-position: center center;
}

.footer {
  width: 100%;
  height: 100px;
  background-color: #333333;
  color: #fff;
  text-align: center;
}

.footer a {
  color: #FFFFFF;
}

.title h1 {
  color: #FAFAFA;
  font-size: 36px;
  margin: 0px 0px 30px 0px;
  text-align: center;
  font-family: 'Slabo'; 
  -webkit-text-stroke: 1px #333333;
}

.qiita_articles {
  width: 100%;
  max-width: 600px;
  margin: auto;
}

.qiita_articles h4 {
  width: 100%;
  text-align: center;
  font-family: 'Slabo';
  font-size: 16px;
  /* margin: 30px auto 10px; */
  display: inline-block;
  position: relative;
  height: 90px;
  line-height: 60px;
  vertical-align: middle;
  padding: 10px 10px 10px 0px;
  background: #f53b57;
  color: #FAFAFA;
  box-sizing: border-box;
  margin-left: auto;
}

.qiita_articles h4::after {
  position: absolute;
  content: '';
  width: 0px;
  height: 0px;
  z-index: 1;
  top: 0;
  right: 0;
  border-width: 46px 20px 45px 0px;
  border-color: transparent #FAFAFA transparent transparent;
  border-style: solid;
}

.to_amazon {
  width: 150px;
  margin: 0 auto;
}

.to_amazon_button {
  width: 110px;
  height: 20px;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 900;
  background: #F3A847;
  color: #FAFAFA;
  padding: 5px;
  text-align: center;
}

.to_amazon_button a {
  color: #FAFAFA;
  text-decoration: none;
}

.qiita_articles_list {
  max-width: 600px;
  margin: 20px auto;
  white-space: pre-wrap;
}


.qiita_articles_container {
  width: 100%;
  font-size: 16px;
  text-align: center;
  font-family: 'Slabo';
  background-color: #FAFAFA;
  padding: 20px;
  margin: auto;
  text-align: left;
  text-decoration: none;
}

.qiita_articles_container p {
  color: #333333;
}

.qiita_articles_container a {
  text-decoration-color:#333333
}

.details_container {
  width: 85%;
  font-size: 16px;
  font-family: 'Slabo';
  background-color: #FAFAFA;
  padding: 20px;
  margin: auto;
  text-decoration: none;
}

.details_container p {
  color: #333333;
}

.details_container a {
  text-decoration-color:#333333
}

.details {
  max-width: 600px;
  margin: 20px auto;
  white-space: pre-wrap;
}

.details p {
  max-width: 200px;
  font-size: 16px;
  font-weight: 900;
  margin: 30px auto;
  border-bottom: double 5px #F89902;
}

.target {
  max-width: 600px;
  margin: 30px auto;
  white-space: pre-wrap;
}

.target p {
  max-width: 200px;
  font-size: 16px;
  font-weight: 900;
  margin: 30px auto;
  border-bottom: double 5px #F89902;
}

.details_title {
  width: 100%;
  max-width: 600px;
  margin: auto;
  text-align: center;
}

.ranking_header {
  width: 85%;
  margin: 10px auto 10px;
}

.ranking_header_text {
  width: 100%;
  font-size: 24px;
  text-align: left;
  font-family: 'Slabo';   
  margin: auto;
  padding: 10px;
  display: inline-block;
  position: relative;
  height: 90px;
  line-height: 27px;
  vertical-align: middle;
  text-align: left;
  font-size: 20px;
  background: #f53b57;
  color: #FAFAFA;
  box-sizing: border-box;
}

.ranking_header_text h1 {
  width: 100%;
  font-size: 24px;
  text-align: left;
  font-family: 'Slabo';   
  color: #FAFAFA;
}

.ranking_description {
  width: 85%;
  margin: auto;
}
  
.ranking_header_text::after {
  position: absolute;
  content: '';
  width: 0px;
  height: 0px;
  z-index: 1;
  top: 0;
  right: 0;
  border-width: 46px 20px 45px 0px;
  border-color: transparent #FAFAFA transparent transparent;
  border-style: solid;
}

.container h2 {
  width: 100%;
  color: #333333;
  font-size: 16px;
  font-family: 'Slabo';           
}

.container h3 {
  width: 5px;
  color: #333333;
  font-size: 20px;
  text-align: left;
  font-family: 'Slabo';
  background:linear-gradient(transparent 80%, #f53b57 0%);
}

.container p {
  width: 100%;
  color: #333333;
  font-size: 16px;
  text-align: center;
  font-family: 'Slabo';
}

.questionHeader {  
  text-align: center;
  font-size: 20px;
  margin-bottom: 30px;
}

.question {
  width: 100%;
  margin: auto;
  text-align: center;
}

/* .rankings {
  width: 100%;
} */

.ranking_item h3 {
  width: 50px;
  margin: 0px;
}

.to_details {
  margin: auto;
  width: 120px;
  height: 20px;
  margin: 10px 10px 0px;
  max-width: 120px;
  font-size: 14px;
  font-weight: 900;
  background: #F3A847;
  color: #FAFAFA;
  padding: 5px;
  text-align: center;
}

.to_details a {
  color: #FAFAFA;
  text-decoration: none;
}

.container button {
  margin: 5px;
}

.ranking p {
  color: black;
  text-align: center;
  font-weight: 200;
  font-size: 14px;
}

.award_container {
  text-align: center;
  height: 30px;
}

.award_container p {
  font-size: 14px;
  font-weight: 600;
}

.award_icon {
  color: #F7C147;
  margin: 0px 0px 0px 0px;
}

.share {
  color: black;
  text-align: center;
}

.startButton {
  width: 100%;
  /* position: absolute; */
  bottom: 10%;
  margin: 3% 0%;
}

.Button {
  font-size: 30px;
}

.body {
  background-color: rgb(14, 168, 14);
}

.advertisement {
  display: block;
  margin: auto;
}

body::before {
  content: "";
  width: 100%;

  top: 0;

  right: 0;

  bottom: 0;

  left: 0;

  background-color: rgb(14, 168, 14);

  opacity: 0.5;

  display: block;
}


/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 26px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #333333;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #fff;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.about_container {
  width: 85%;
  background-color: #FAFAFA;
  padding: 20px;
  margin: auto;
}

.inquiry_container {
  width: 85%;
  background-color: #FAFAFA;
  padding: 20px;
  margin: auto;
  text-align: center
}

.inquiry_container h2 {
  text-align: center
}

.inquiry_input {
  width: 200px;
}

.spinner {
  animation: spin infinite 5s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}